$primary: #8A05F1; // primary 컬러변경

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// .w-33 추가
$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                33: 33.3%,
              )
            ),
        )
      ),
  )
);

@import "bootstrap/scss/bootstrap";

.container-max-1200 {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.container-max-640 {
  width: 100%;
  max-width: 660px;
  padding-right: $container-padding-x;
  padding-left: $container-padding-x;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
}

ul {
  padding-left: 0;
  list-style: none;
}
